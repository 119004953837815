<template>
  <Container class="mt-24 md:mt-56">
    <div class="max-w-6xl">
      <Tilt
        contain
        class="bg-elbworx-yellow bg-[linear-gradient(90deg,theme('colors.elbworx-yellow')_0%,theme('colors.elbworx-cyan')_150%)]"
      >
        <Padding class="ml-auto max-w-[90ch] py-10 font-extrabold italic md:py-16">
          <p class="mb-4 text-[3rem]/[1] md:text-[5rem]/[1]">Alles klar?</p>
          <p class="text-xl md:text-2xl">
            elbworx ist ein Kollektiv von kreativen Lösungsfindern. Wir hören Dir zu, stellen die
            richtigen Fragen – und noch während wir mit Dir sprechen, entwickeln wir bereits Ideen,
            wie wir gemeinsam zu einer Lösung gelangen: in-time und in-budget.
            <br />
            <br />
            Welches Problem dürfen wir für Dich lösen?
          </p>
          <AnimatedButton class="mt-6 !px-4" href="/kontakt">
            Tritt mit uns in Kontakt
          </AnimatedButton>
        </Padding>
      </Tilt>
    </div>
  </Container>
</template>
